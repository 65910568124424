
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, watch, onBeforeMount, Ref } from 'vue';
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampEmptyListFeedbackV2,
  CampFooterPaginationControl,
  CampBtnGenerateExcel
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import moment from 'moment';
import { ptBr } from 'element-plus/es/locale';
import { ICompanyData } from '@/store/CompaniesStore';
import { campGoToTopByScrollMode, campHandleCamelCaseToTitleCase } from '@/composables/Helpers';
import { ICompanyResponse } from '@/views/Companies/ListPage.vue';
import { ILogPushNotification as ILogPushNotificationFromDeveloper, TOperationType } from '@/views/Developer/PushNotificationLogList.vue';

interface ILogPushNotification extends ILogPushNotificationFromDeveloper {
  competitorCPF: string
  competitorID: string
  competitorName: string
  created_at: string
  frequency: string
  id: number
  id_job: number
  jobName: string
  key: number | string
  label: string
  message: string
  return: any
  selected: boolean | number
  title: string
  updated_at: string
  type: string
}

interface ICompetitorData {
  name: string
  cpf: string
  id: string
}

interface ICompetitorResponse {
  data: {
    data: ICompetitorData[],
    error: boolean,
    message: string,
    errorCode: string,
  }
}

interface ILogResponse {
  data: {
    data: {
      data: ILogPushNotification[],
      current_page: number,
      from: number,
      last_page: number,
      per_page: number,
      to: number,
      total: number,
    },
    error: boolean,
    message: string,
    errorCode: string,
  }
}

interface IAvailableJobsResponse {
  data: {
    data: IAvailableJobsData[],
    error: boolean,
    message: string,
    errorCode: string,
  }
}

interface IAvailableJobsData {
  jobName: string
  frequency: string
  id: number
  label: string
  message: string
  selected: boolean
  title: string
}

interface IAvailableJobsOption {
  label: string
  key: number | string
}

type TStatusCodeType = "information" | "successful" | "redirection" | "client-error" | "server-error"

type TBodyParams = {
  page?: number
  oldest?: boolean
  success?: boolean
  statusCodeType?: TStatusCodeType
  operationTypeList?: TOperationType[]
  companyIdList?: number[]
  storeIdList?: number[]
  competitorIdList?: string[]
  jobIdList?: number[]
  rangeDate?: Date
  startDate?: Date
  endDate?: Date
}

type TParamOption = {
  key: string | number;
  label: string;
  value?: string | number;
}

const OLDEST_LIST: TParamOption[] = [
  { key: 0, label: "Mais recente" },
  { key: 1, label: "Mais antigo" },
]

const STATUS_LIST: TParamOption[] = [
  { key: 0, label: "Erro" },
  { key: 1, label: "Sucesso" },
]

const FEEDBACK_LIST: TParamOption[] = [
  { key: "information", label: "Status 1XX" },
  { key: "successful", label: "Status 2XX" },
  { key: "redirection", label: "Status 3XX" },
  { key: "client-error", label: "Status 4XX" },
  { key: "server-error", label: "Status 5XX" },
]

const OPERATION_TYPE_LIST: TParamOption[] = [
  { key: "auto", label: "Automático" },
  { key: "semi-auto", label: "Semi automático" },
  { key: "custom", label: "Personalizado" },
]

const handlerError = (error: AxiosError | unknown, showTimeAlert: Function) => {
  if(error instanceof AxiosError)
    showTimeAlert(error.response?.data.message, "error")
  else
    showTimeAlert("Algo deu errado!", "error")
}

const handleJoinObjectList = (
  logList: ILogPushNotification[],
  availableJobsList: IAvailableJobsOption[],
  competitorList: ICompetitorData[]
) => {
  return logList
    .map(log => {
      const competitor = competitorList.find(item => log.competitorID === item.id)
      const job = availableJobsList.find(job => job.key === log.id_job)
      return {
        ...log,
        ...job,
        label: job?.label || "-",
        competitorName: competitor?.name || "-",
        competitorCPF: competitor?.cpf || "-",
        user_name: log.user_name || "Sistema",
        type: (log.operation_type === "auto" && "Automático") || (log.operation_type === "semi-auto" && "Semi automático") || (log.operation_type === "custom" && "Personalizado") || "-"
      }
    })
    .map(el => ({
      ...el,
      frequency: el.frequency || "-",
      send_to: (el.recipient_company_id && `Companhia: ${el.recipient_campany_name}`) ||
               (el.recipient_store_id && `Loja: ${el.recipient_store_name}`) ||
               `Vendedor: ${el.competitorName}`
    }))
}

const handleFetchJoinObjectList = async(logPushNotificationList: ILogPushNotification[], availableJobsList:IAvailableJobsOption[], competitorList: Ref<ICompetitorData[] | []>) => {
  const logsResult = logPushNotificationList.map(log => {
    if(typeof log.return === "string") {
      const jsonParsed = JSON.parse(log.return)[0]
      return {
        ...log,
        status_header: jsonParsed.statusCode,
        status: (jsonParsed.statusCode >= 200 && jsonParsed.statusCode < 300) ? "Sucesso" : "Erro",
        competitorID: jsonParsed.competitors_id,
        title: log.operation_type === "custom" ? jsonParsed.title : log.title,
        message: log.operation_type === "custom" ? jsonParsed.message : log.message,
        created_at: moment(log.created_at).format("DD/MM/YYYY HH:mm:ss"),
      }
    }
    return { ...log, status_header: null, competitorID: null, status: "-" }
  })

  return handleJoinObjectList(logsResult, availableJobsList, [ ...competitorList.value ])
}

const handleUpdateLogListRender = (logList: Ref<ILogPushNotification[] | null>, logListRender: Ref<ILogPushNotification[] | null>,) => {
  if(Array.isArray(logList.value) && logList.value.length > 0)
    logListRender.value = logList.value.map(log => ({ ...log }))
}

const handleFilterObjectsByKeyword = (
  logList: Ref<ILogPushNotification[] | null>,
  logListRender: Ref<ILogPushNotification[] | null>,
  keywordSearch: Ref<string>
): boolean => {
  if(!logList.value)
    return true
  logListRender.value = logList.value.filter((item) => {
    for (const key in item) {
      const propValue = item[key];
      if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase()))
        return true;
      if (typeof propValue === 'number' && keywordSearch.toString() === propValue.toString())
        return true;
    }
    return false;
  });
  return false
}

const handleBodyParams = (bodyParamsModel: Ref<TBodyParams>, bodyParamsCompleted: Ref<TBodyParams>) => {
  bodyParamsCompleted.value = {}

  if(Array.isArray(bodyParamsModel.value.companyIdList) && bodyParamsModel.value.companyIdList.length > 0)
    bodyParamsCompleted.value.companyIdList = [ ...bodyParamsModel.value.companyIdList ]

  if(Array.isArray(bodyParamsModel.value.storeIdList) && bodyParamsModel.value.storeIdList.length > 0)
    bodyParamsCompleted.value.storeIdList = [ ...bodyParamsModel.value.storeIdList ]

  if(Array.isArray(bodyParamsModel.value.competitorIdList) && bodyParamsModel.value.competitorIdList.length > 0)
    bodyParamsCompleted.value.competitorIdList = [ ...bodyParamsModel.value.competitorIdList ]

  if(Array.isArray(bodyParamsModel.value.jobIdList) && bodyParamsModel.value.jobIdList.length > 0)
    bodyParamsCompleted.value.jobIdList = [ ...bodyParamsModel.value.jobIdList ]

  if(Array.isArray(bodyParamsModel.value.rangeDate) && bodyParamsModel.value.rangeDate.length > 1) {
    bodyParamsCompleted.value.startDate = bodyParamsModel.value.rangeDate[0]
    bodyParamsCompleted.value.endDate = bodyParamsModel.value.rangeDate[1]
  }

  if(Array.isArray(bodyParamsModel.value.operationTypeList) && bodyParamsModel.value.operationTypeList.length > 0)
    bodyParamsCompleted.value.operationTypeList = [ ...bodyParamsModel.value.operationTypeList ]

  if(typeof bodyParamsModel.value.oldest === "number")
    bodyParamsCompleted.value.oldest = bodyParamsModel.value.oldest

  if(typeof bodyParamsModel.value.success === "number")
    bodyParamsCompleted.value.success = bodyParamsModel.value.success

  if(bodyParamsModel.value.statusCodeType)
    bodyParamsCompleted.value.statusCodeType = bodyParamsModel.value.statusCodeType
}

export default defineComponent({
  name: "ReportPushNotifications",
  components: {
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampEmptyListFeedbackV2,
    CampFooterPaginationControl,
    CampBtnGenerateExcel
  },
  setup() {
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const isLoading = ref(true)
    const indexCurrentPage = ref(1)
    const totalPage = ref(1)
    const companyList = ref<ICompanyData[] | null>(null)
    const storeList = ref<ICompanyData[] | null>(null)
    const availableJobsList = ref<IAvailableJobsOption[] | null>(null)
    const logList = ref<ILogPushNotification[] | null>(null)
    const logListRender = ref<ILogPushNotification[] | null>(null)
    const keywordSearch = ref("")
    const bodyParamsModel = ref<TBodyParams>({})
    const bodyParamsCompleted= ref<TBodyParams>({})
    const competitorList = ref<ICompetitorData[] | []>([])

    /** Functions */
    async function getDatas() {
      try {
        isLoading.value = true
        type TResponseList = [ ILogResponse, ICompanyResponse, ICompanyResponse, ICompetitorResponse, IAvailableJobsResponse ]
        const [ logsResponse, companiesResponse, storesResponse, competitorsResponse, availableJobsResponse ]: TResponseList = await Promise.all([
          axios.post('/api/generateLogPushNotificationList'),
          axios.get('/api/getAllCompanies'),
          axios.get('/api/getAllStores'),
          axios.get('/api/getCompetitorList'),
          axios.get('/api/getJobAssignments'),
        ]);
        availableJobsList.value = availableJobsResponse.data.data.map(el => ({ ...el, key: el.id, label: campHandleCamelCaseToTitleCase(el.title) }))
        competitorList.value = competitorsResponse.data.data.map(el => ({ id: el.id, cpf: el.cpf, name: el.name }))
        logList.value = await handleFetchJoinObjectList(logsResponse?.data?.data?.data, availableJobsList.value, competitorList)
        companyList.value = companiesResponse.data.data.map(el => ({ ...el })) || null
        storeList.value = storesResponse.data.data.map(el => ({ ...el })) || null
        indexCurrentPage.value = 1
        totalPage.value = logsResponse.data.data.last_page
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        isLoading.value = false
      }
    }

    async function handleSearchLogList() {
      try {
        indexCurrentPage.value = 1
        handleBodyParams(bodyParamsModel, bodyParamsCompleted)
        isLoading.value = true
        const response: ILogResponse = await axios.post(
          "/api/generateLogPushNotificationList",
          { ...(JSON.parse(JSON.stringify(bodyParamsCompleted.value))), page: indexCurrentPage.value }
        )
        const { data } = response.data
        logList.value = await handleFetchJoinObjectList(data.data, availableJobsList.value || [], competitorList)
        totalPage.value = response.data.data.last_page
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        isLoading.value = false
      }
    }

    async function handlePaginationRendering(pageID: number) {
      try {
        loaderStore.open()
        indexCurrentPage.value = pageID
        const response: ILogResponse = await axios.post(
          "/api/generateLogPushNotificationList",
          { ...(JSON.parse(JSON.stringify(bodyParamsCompleted.value))), page: indexCurrentPage.value }
        )
        const { data } = response.data
        logList.value = await handleFetchJoinObjectList(data.data, availableJobsList.value || [], competitorList)
        campGoToTopByScrollMode(false)
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        loaderStore.close()
      }
    }

    /** Life Cycles */
    onBeforeMount(() => getDatas())

    watch(() => logList.value, () => {
      handleUpdateLogListRender(logList, logListRender)
      handleFilterObjectsByKeyword(logList, logListRender, keywordSearch)
    }, { deep: true })

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword(logList, logListRender, keywordSearch))

    return {
      moment,
      ptBr,
      isLoading,
      keywordSearch,
      logListRender,
      companyList,
      storeList,
      availableJobsList,
      bodyParamsModel,
      OLDEST_LIST,
      STATUS_LIST,
      FEEDBACK_LIST,
      OPERATION_TYPE_LIST,
      handleSearchLogList,
      handlePaginationRendering,
      indexCurrentPage,
      totalPage,
      competitorList,
    }
  }
})
